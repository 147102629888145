<template>
  <v-container class="px-2">
    <v-card rounded class="transparent" flat>
      <v-text-field
        :error-messages="myDisplayNameErrors"
        label="Display Name"
        ref="mydisplayname"
        v-model="myDisplayName"
        :counter="50"
        prepend-icon="mdi-clipboard-account"
        @input="$v.myDisplayName.$touch()"
        @blur="myDisplayName = capitalize(myDisplayName)"
      />
      <v-text-field
        :error-messages="firstNameErrors"
        label="First Name"
        ref="fname"
        v-model="fname"
        :counter="50"
        prepend-icon="mdi-account-circle"
        @input="$v.fname.$touch()"
        @blur="fname = capitalize(fname)"
      />
      <v-text-field
        :error-messages="lastNameErrors"
        label="Last Name"
        v-model="lname"
        ref="lname"
        prepend-icon="mdi-account-circle"
        :counter="50"
        @input="$v.lname.$touch()"
        @blur="lname = capitalize(lname)"
      />
      <v-text-field
        :error-messages="middleNameErrors"
        label="Middle name"
        ref="mname"
        v-model="mname"
        prepend-icon="mdi-account-circle"
        :counter="50"
        @input="$v.mname.$touch()"
        @blur="mname = capitalize(mname)"
      />

      <v-row>
        <v-col cols="12" md="4">
          <v-select
            v-model="mysex"
            prepend-icon="mdi-gender-male-female"
            :items="sexList"
            label="Sex"
            outlined
          />
        </v-col>
      </v-row>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="dob"
            label="Date of Birth"
            prepend-icon="mdi-cake-variant"
            readonly
            v-on="on"
          />
        </template>
        <v-date-picker
          ref="picker"
          v-model="dob"
          :max="new Date().toISOString().substr(0, 10)"
          min="1920-01-01"
          @change="save"
        />
      </v-menu>
      <v-row>
        <v-col cols="12">
          <v-select
            label="Timezone"
            v-model="mytimezone"
            prepend-icon="mdi-globe-model"
            :items="timezonesList"
            filled
            item-text="name"
            item-value="name"
            item-color="success"
            color="success"
          />
        </v-col>
      </v-row>

      <v-card-actions class="pa-0">
        <v-spacer />
        <BaseActionButton
          text
          dark
          class="charcoal paper--text"
          :large="!isPhone"
          v-bind="$attrs"
          :disabled="this.$v.$anyError"
          @clickedThis="savePersonalInfo"
        />
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
export default {
  mixins: [util, validationMixin],
  props: ['accountInfo'],
  data: () => ({
    fname: '',
    lname: '',
    mname: '',
    myDisplayName: '',
    mysex: '',
    mygender: '',
    sexList: ['Male', 'Female'],
    timezonesList: [],
    mytimezone: '',
    dob: null,
    date: null,
    menu: false
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => this.$refs.picker.activePicker == 'YEAR')
    }
  },
  mounted() {
    this.loadTimezones()
    this.fname = this.accountInfo.fname
    this.lname = this.accountInfo.lname
    this.mname = this.accountInfo.mname
    this.myDisplayName = this.accountInfo.display_name
    this.dob = this.accountInfo.dob
    this.mysex = this.accountInfo.sex
    this.mytimezone = this.accountInfo.timezone
  },
  validations: {
    myDisplayName: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(50)
    },
    fname: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(50)
    },
    lname: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(50)
    },
    mname: {
      minLength: minLength(0),
      maxLength: maxLength(50)
    }
  },
  computed: {
    firstNameErrors() {
      const errors = []
      if (!this.$v.fname.$dirty) return errors
      !this.$v.fname.maxLength &&
        errors.push('First name must be at most 50 characters long')
      !this.$v.fname.required && errors.push('First name is required.')
      return errors
    },
    lastNameErrors() {
      const errors = []
      if (!this.$v.lname.$dirty) return errors
      !this.$v.lname.maxLength &&
        errors.push('First name must be at most 50 characters long')
      !this.$v.lname.required && errors.push('Last name is required.')
      return errors
    },
    myDisplayNameErrors() {
      const errors = []
      if (!this.$v.myDisplayName.$dirty) return errors
      !this.$v.myDisplayName.maxLength &&
        errors.push('Display name must be at most 50 characters long')
      !this.$v.myDisplayName.required &&
        errors.push('Display name is required.')
      return errors
    },
    middleNameErrors() {
      const errors = []
      if (!this.$v.lname.$dirty) return errors
      !this.$v.lname.maxLength &&
        errors.push('Middle name must be at most 50 characters long')
      return errors
    },

    ...appConfig
  },
  methods: {
    save(dob) {
      this.$refs.menu.save(dob)
    },
    loadTimezones() {
      {
        return axios.get(this.baseURL + '/util/tz/all').then(response => {
          if (response.status == 200) {
            this.timezonesList = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
      }
    },
    savePersonalInfo() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.toast('Please correct the data and try again.')
        return
      } else {
        const updates = {
          lname: this.lname,
          fname: this.fname,
          mname: this.mname,
          display_name: this.myDisplayName,
          dob: this.dob,
          sex: this.mysex,
          timezone: this.mytimezone,
          gender: this.mygender
        }
        return axios.put(this.baseURL + '/users', updates).then(response => {
          if (response.status == 200) {
            this.$emit('updateInfo')
            this.toast('Personal information was succesfully updated.')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              {
                root: true
              }
            )
            return
          }
        })
      }
    }
  }
}
</script>
